import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Avatar } from "primereact/avatar";
import { DataTable } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { getPaymentRequestList } from "../../../../../redux/slices/payment_request_slice";
import * as XLSX from "xlsx";

export default function PaymentRequest({ permissions }) {
    const { data: list } = useSelector((state) => state.paymentRequest);
    console.log(list,"list")
    const [showProfileDialog, setProfileDialog] = useState(false);
    const dispatch = useDispatch();
    const [editDialog, setEditDialog] = useState(false);
    const [editUser, setEditUser] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [selectedPaymentsRows, setSelectedPaymentsRows] = useState(null);
    const dt = useRef(null);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        setGlobalFilterValue(value);
    };

    const dialogFuncMap = {
        editDialog: setEditDialog,
        showProfileDialog: setProfileDialog,
    };
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    };
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    const methodBodyTemp = (rowData) => {
        return <div className={rowData.method === "unpaid" ? "bg_red" : rowData.method === "zindigi" ? "bg_zindigi" : "bg_green"}>{rowData.method}</div>;
    };
    const createdAtTemplate = (rowData) => {
        return <>{moment(rowData.createdAt).format("MMMM Do YYYY, h:mm a")}</>;
    };
    useEffect(() => {
        dispatch(getPaymentRequestList({ status: "paid", text: globalFilterValue }));
    }, []);

    // const actionTemplate = (rowData) => {
    //     return (
    //         <div className="Edit_Icon">
    //             {permissions?.edit && (
    //                 <Avatar
    //                     icon="pi pi-check"
    //                     // className="m-2 p-button-rounded p-button-success"
    //                     className="m-2 p-button-rounded bg-green-500 text-white"
    //                     size="large"
    //                     shape="circle"
    //                     tooltipOptions={{ position: "top" }}
    //                     tooltip="View Profile"
    //                     onClick={() => {
    //                         setEditUser(rowData);
    //                         onClick("showProfileDialog");
    //                     }}
    //                 />
    //             )}
    //             {permissions?.add && (
    //                 <Avatar
    //                     icon="pi pi-times"
    //                     // className="m-2 p-button-danger p-button-rounded"
    //                     className="m-2 p-button-rounded bg-danger text-white"
    //                     size="large"
    //                     shape="circle"
    //                     tooltipOptions={{ position: "top" }}
    //                     tooltip="View Profile"
    //                     onClick={() => {
    //                         setEditUser(rowData);
    //                         onClick("showProfileDialog");
    //                     }}
    //                 />
    //             )}
    //         </div>
    //     );
    // };
    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const exportSelectedRowsToExcel = () => {
        if (!selectedPaymentsRows || selectedPaymentsRows.length === 0) {
            alert("Please select rows to export."); // Optional: Replace with a notification
            return;
        }
    
        // Prepare data for Excel
        const excelData = selectedPaymentsRows.map((row) => ({
            "First Name": row?.userId?.firstName || "",
            "Last Name": row?.userId?.lastName || "",
            "Mobile": row?.userId?.mobile || "",
            "Email": row?.userId?.email || "",
            "Total Amount (PKR)": row?.amount || "",
            "Transaction Id":row?.transactionId || "",
        }));
    
        // Convert data to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(excelData);
    
        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Payment Requests");
    
        // Generate and trigger the download
        XLSX.writeFile(workbook, "selected_payment_requests.xlsx");
    };
    
    return (
        <>
            {/* <Dialog draggable={false} resizable={false} header="Profile" position="center" className="text-center" visible={showProfileDialog} style={{ width: "60vw", height: "" }} onHide={() => onHide("showProfileDialog")}>
                <div>{editUser != null && <UserAccountProfileDialog permissions={permissions} userId={editUser._id} user={editUser} userType="1" />}</div>
            </Dialog> */}

            <div className="card">
                <div className="grid pb-3">
                    <div className="col-12 md:col-6">
                        <h4>Payment Request</h4>
                    </div>
                    <div className="col-12 md:col-6">
                        <div className="mb-9 text-right">
                            <span className="p-input-icon-right mr-3">
                                <InputText className="mbl_view" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                            </span>
                            <Button style={{ fontSize: "14px" }} className="p-button-success p-button-rounded p-button-sm custom___width" label="Export to Excel" iconPos="right" onClick={exportSelectedRowsToExcel} />
                        </div>
                    </div>
                </div>
                <DataTable
                    filter
                    rows={50}
                    paginator
                    value={list}
                    responsiveLayout="scroll"
                    filters={filters}
                    ref={dt}
                    emptyMessage="No record found."
                    globalFilterFields={["firstName", "lastName", "cnic", "email", "mobile"]}
                    selectionMode={'checkbox'}
                    selection={selectedPaymentsRows}
                    onSelectionChange={(e) => setSelectedPaymentsRows(e.value)}
                    dataKey="_id"
                >
                    <Column className="maker_colm" selectionMode="multiple" style={{ width: '3em' }} />
                    <Column className="maker_colm" field="transactionId" header="Transaction Id" />
                    <Column className="maker_colm" field="userId.firstName" header="First Name" />
                    <Column className="maker_colm" field="userId.lastName" header="Last Name" />
                    <Column className="maker_colm" field="userId.mobile" header="Mobile" />
                    <Column className="maker_colm" field="userId.email" header="Email" />
                    <Column className="maker_colm" field="paymentGatewayId.name" header="Name" />
                    <Column className="maker_colm" field="amount" header="Total Amount(PKR)" style={{ textAlign: "center" }} />
                    {/* <Column className="maker_colm" header="Action" body={actionTemplate} /> */}
                </DataTable>
            </div>
        </>
    );
}
