import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import EditVehicleDialog from "../components/add_edit_vehicles_dialog";
import { getVehicleDocumentsList } from "../../../../../redux/slices/vehicle_verification_slice";
import { Dropdown } from "primereact/dropdown";
import CustomPageHeader from "../../../components/custom_page_header";
import { Paginator } from "primereact/paginator";
import moment from "moment";
export default function VehiclesScreen({ permissions }) {
    //redux
    const { data: list, loading, count } = useSelector((state) => state.vehicleDocuments);
    const dispatch = useDispatch();

    //usestate hooks
    const [selectedCategory, setSelectedCategory] = useState("pending");
    const [editData, setEditData] = useState(null);
    const [showEditDialog, setEditDialog] = useState(false);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(50);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [defaultImage, setDefaultImage] = useState("app/black-watch.jpg");

    const [currentPage, setCurrentPage] = useState(0);
    //dropdown status list
    const statusList = [
        { status: "Pending", value: "pending" },
        { status: "Rejected", value: "rejected" },
        { status: "Verified", value: "verified" },
    ];

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const loginUser = useSelector((state) => state.loginUser);
    const { user } = loginUser;
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    // dialog
    const dialogFuncMap = {
        showEditDialog: setEditDialog,
    };

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    const rowClass = (rowData) => {
        if (rowData.documents[0].status === "verified") {
            return {
                "bg-success": true, // Green background for active
            };
        } else if (rowData.documents[0].status === "rejected") {
            return {
                "bg-danger": true, // Red background for inactive
            };
        } else if (rowData.documents[0].status === "pending") {
            return {
                "bg-yellow": true, // Red background for inactive
            }; // No class if no status
        }
    };

    // const statusTemplate = (rowData) => {
    //     console.log(rowData.documents[0].status);
    //     // const val = rowData.documents[0].status;
    //     // const Status = val.charAt(0).toUpperCase() + val.slice(1)
    //     return <div className={rowData.documents[0].status === "pending" ? "bg_yellow" : rowData.documents[0].status === "rejected" ? "bg_red" : "bg_green"}>{rowData.documents[0].status}</div>;
    // };
    const createdAtTemplate = (rowData) => {
        return <>{/* {moment(rowData?.createdAt).format('MMMM Do YYYY, h:mm a')} */}</>;
    };
    const actionTemplate = (rowData) => {
        // console.log(rowData,"rowData")
        return (
            // className={rowData.active.status ? "green" : "red"}
            //{rowData.documents[0].status === "pending" ? "bg_yellow" : rowData.documents[0].status === "rejected" ? "bg_red" : "bg_green"}
            <div className="Edit_Icon">
                <Button
                    tooltip="Documents"
                    icon="pi pi-copy"
                    tooltipOptions={{ position: "top" }}
                    className={rowData.documents[0].status === "pending" ? "edit m-2 p-button-warning p-button-rounded" : rowData.documents[0].status === "rejected" ? "edit m-2 p-button-danger p-button-rounded" : "edit m-2 p-button-green p-button-rounded"}
                    // className="edit m-2 p-button-success p-button-rounded"
                    onClick={() => {
                        setEditData(rowData);
                        onClick("showEditDialog");
                    }}
                />
            </div>
        );
    };

    //useeffect hooks
    useEffect(() => {
        dispatch(getVehicleDocumentsList({ status: selectedCategory, pageNumber: 0, perPageRecords: rows }));
    }, [selectedCategory, dispatch]);

    const onPageChange = (event) => {
        setCurrentPage(event.first / event.rows);
        setFirst(event.first);
        setRows(event.rows);
        dispatch(getVehicleDocumentsList({ status: selectedCategory, pageNumber: event.first / event.rows, perPageRecords: event.rows }));
    };

    return (
        <>
            <Dialog draggable={false} resizable={false} header="Documents" visible={showEditDialog} onHide={() => onHide("showEditDialog")} breakpoints={{ "960px": "75vw", "640px": "100vw" }} style={{ width: "70vw" }}>
                <EditVehicleDialog permissions={permissions} editData={editData} onHide={() => onHide("showEditDialog")} status={selectedCategory} pageNumber={currentPage} />
            </Dialog>

            <div className="card">
                <div className="flex flex-row justify-content-between align-items-baseline pb-3">
                    <CustomPageHeader title={"Vehicles Document Verification"} />
                    {user?.role?.role !== "Approver" && (
                        <div className="mb-9 text-right">
                            <span className="p-input-icon-right">
                                <InputText className="mb-3 mr-2" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                                <Dropdown className="" value={selectedCategory} options={statusList} optionLabel="status" optionValue="value" onChange={(e) => setSelectedCategory(e.value)} placeholder="Select a Status" />
                            </span>
                        </div>
                    )}
                </div>
                <DataTable rowClassName={rowClass} responsiveLayout="scroll" value={list} loading={loading} filters={filters} emptyMessage="No record found.">
                    {/* {console.log(list)} */}
                    <Column className="maker_colm" field="model.model" header="Model" />
                    <Column className="maker_colm" field="registrationNumber" header="Registration Number" />
                    <Column className="maker_colm" field="registrationCity.city" header="Registration City" />
                    <Column className="maker_colm" field="registrationProvince.name" header="Registration Province" />
                    <Column className="maker_colm" field="color.color" header="Color" />
                    {/* <Column field="seatingCapacity" header="Seating Capacity" /> */}
                    <Column className="maker_colm" filter field="year" header="Year" />
                    {/* <Column body={createdAtTemplate} header="Created At" style={{ minWidth: "12rem" }}  /> */}
                    {/* <Column className="maker_colm" field={statusTemplate} header="Status" /> */}
                    <Column className="maker_colm" header="Action" body={actionTemplate} />
                </DataTable>
                <Paginator first={first} rows={rows} totalRecords={count} onPageChange={onPageChange} rowsPerPageOptions={[10, 20, 30, 50]} />
            </div>
        </>
    );
}
