import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { Avatar } from "primereact/avatar";
import { Dialog } from "primereact/dialog";
import EditUserDialog from "../../users/components/edit_passenger_dialog";
import { Image } from "primereact/image";
import appUrl from "../../../constants/appUrl";
import UserProfileDialog from "../../users/components/user_profile_dialog";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import VerificationDialog from "../components/licence_verification_dialog";
import { toast } from "react-toastify";

export default function DriversScreen({ permissions }) {
    //redux

    //hooks
    const [showEditDialog, setEditDialog] = useState(false);
    const [showProfileDialog, setProfileDialog] = useState(false);
    const [showDocumentsDialog, setDocumentsDialog] = useState(false);
    const [editUser, setEditUser] = useState(undefined);
    const [editUserId, setEditUserId] = useState(undefined);
    const [editDocument, setEditDocument] = useState(undefined);
    const [showVerificationDialog, setVerificationDialog] = useState(false);
    const [showLicenceDialog, setLicenceDialog] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    useEffect(() => {}, []);

    // dialog
    const dialogFuncMap = {
        showEditDialog: setEditDialog,
        showProfileDialog: setProfileDialog,
        showDocumentsDialog: setDocumentsDialog,
        showVerificationDialog: setVerificationDialog,
        showLicenceDialog: setLicenceDialog,
    };
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    };
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    //coponents
    const userTypeTemp = (rowData) => {
        return <div className="">{rowData.userType === "3" ? "Both" : rowData.userType === "2" ? "Passenger" : "Driver"}</div>;
    };

    const activeStatusBody = (rowData) => {
        return <div className={rowData.active.status ? "green" : "red"}>{rowData.active.status ? "Active" : "In Active"}</div>;
    };
    const actionTemplate = (rowData) => {
        return (
            <div className="Edit_Icon">
                {permissions?.edit && (
                    <Avatar
                        icon="pi pi-user"
                        className="m-2"
                        size="large"
                        shape="circle"
                        tooltipOptions={{ position: "top" }}
                        tooltip="View Profile"
                        onClick={() => {
                            // setEditUser(rowData);
                            // onClick("showProfileDialog")
                        }}
                    />
                )}
            </div>
        );
    };

    const firstNameTemp = (rowData) => {
        const name = rowData.firstName;
        return <div>{name.charAt(0).toUpperCase() + name.slice(1)}</div>;
    };
    const lastNameTemp = (rowData) => {
        const name = rowData.lastName;
        return <div>{name.charAt(0).toUpperCase() + name.slice(1)}</div>;
    };

    const documentTemplate = (rowData) => {
        return (
            <div className="Edit_Icon">
                {permissions?.edit && (
                    <Button
                        tooltip="License Verification"
                        icon="pi pi-clone"
                        tooltipOptions={{ position: "top" }}
                        className="edit m-2"
                        onClick={() => {
                            const doc = rowData.documents.find((item) => item.type === "license");
                            if (doc) {
                                setEditDocument(doc);
                                setEditUserId(rowData._id);
                                onClick("showVerificationDialog");
                            } else {
                                toast.warn("No document found");
                            }
                        }}
                    />
                )}
                {permissions?.edit && (
                    <Button
                        tooltip="CNIC verification"
                        icon="pi pi-id-card"
                        tooltipOptions={{ position: "top" }}
                        className="edit m-2 p-button-success p-button-rounded"
                        onClick={() => {
                            const doc = rowData.documents.find((item) => item.type === "cnic");
                            if (doc) {
                                setEditDocument(doc);
                                setEditUserId(rowData._id);
                                onClick("showVerificationDialog");
                            } else {
                                toast.warn("No document found");
                            }
                        }}
                    />
                )}
            </div>
        );
    };

    return (
        <>
            <Dialog draggable={false} resizable={false} header="Update driver status" visible={showEditDialog} onHide={() => onHide("showEditDialog")} breakpoints={{ "960px": "75vw", "640px": "100vw" }} style={{ width: "70vw" }}>
                <EditUserDialog permissions={permissions} editUser={editUser} onHide={() => onHide("showEditDialog")} />
            </Dialog>

            <Dialog draggable={false} resizable={false} header="License Verification" visible={showVerificationDialog} onHide={() => onHide("showVerificationDialog")} breakpoints={{ "960px": "75vw", "640px": "100vw" }} style={{ width: "70vw" }}>
                <VerificationDialog permissions={permissions} userId={editUserId} document={editDocument} onHide={() => onHide("showVerificationDialog")} />
            </Dialog>

            <Dialog draggable={false} resizable={false} header="Profile" position="bottom" className="text-center" visible={showProfileDialog} style={{ width: "90vw", height: "90vh" }} onHide={() => onHide("showProfileDialog")}>
                <div>{editUser != null && <UserProfileDialog permissions={permissions} userId={editUser._id} user={editUser} userType="1" />}</div>
            </Dialog>

            <div className="card">
                <div className="mb-9 text-right">
                    <span className="p-input-icon-right">
                        <InputText className="mb-3" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                    </span>
                </div>
                <DataTable rows={10} paginator responsiveLayout="scroll" value={[]} key="_id" emptyMessage="No record found." filters={filters} globalFilterFields={["firstName", "lastName", "cnic", "mobile"]}>
                    <Column className="maker_colm"
                        header="Image"
                        body={(data) => {
                            return (
                                <div>
                                    <Image width="50px" preview template="Preview" src={`${appUrl.fileBaseUrl}${data?.profileImage}`} />
                                </div>
                            );
                        }}
                    />
                    <Column className="maker_colm" filter body={firstNameTemp} header="First Name" />
                    <Column className="maker_colm" filter body={lastNameTemp} header="Last Name" />
                    <Column className="maker_colm" filter field="cnic" header="CNIC" />
                    <Column className="maker_colm" filter field="mobile" header="Mobile" />
                    <Column className="maker_colm" filter body={userTypeTemp} header="Type" />
                    <Column className="maker_colm" filter field="gender" header="Gender" />
                    <Column className="maker_colm" filter body={activeStatusBody} header="Status" />
                    <Column className="maker_colm" filter body={documentTemplate} header="Documents" />
                    <Column className="maker_colm" header="Action" body={actionTemplate} />
                </DataTable>
            </div>
        </>
    );
}
